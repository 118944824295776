import React from "react"
import PropTypes from "prop-types"

import CustomImage from "@components/common/CustomImage"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"

import moment from "moment"

import "./style.scss"

const TimelineItem = props => {
  return (
    <div className="timeline-item">
      <div className="timeline-item__image-container">
        <CustomImage
          classNameName="timeline-item__image"
          src={(props.image || {}).sourceUrl}
          alt={(props.image || {}).altText}
        />
      </div>
      <div className="timeline-item__content">
        <p className="system-xs mb-10px">{moment(props.date).year()}</p>
        <HeadingText
          className="mb-20px"
          size="xs"
          color="primary-dark"
          text={props.title}
        />
        <BodyText size="md" color="dark" text={props.description} />
      </div>
    </div>
  )
}

TimelineItem.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    sourceUrl: PropTypes.string,
  }),
}
TimelineItem.defaultProps = {
  image: {},
}

export default TimelineItem
