// https://www.selbekk.io/blog/2019/08/how-to-fade-in-content-as-it-scrolls-into-view/

import React from "react"
import PropTypes from "prop-types"

import "./style.scss"

const FadeInSection = props => {
  const [isVisible, setVisible] = React.useState(true)
  const domRef = React.useRef()

  React.useEffect(() => {
    if (props.disabled) return
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting))
      },
      {
        rootMargin: "0px 0px -15% 0px", // trigger when visible and specific amount past bottom
      }
    )
    const current = domRef.current
    observer.observe(current)
    return () => observer.unobserve(current)
  }, [props.disabled])
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}

FadeInSection.propTypes = {
  disabled: PropTypes.bool,
}
FadeInSection.defaultProps = {
  disabled: false,
}

export default FadeInSection
