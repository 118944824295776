import React, { useState } from "react"
import PropTypes from "prop-types"

import language from "@language"

import ArticleMarkdownContent from "@components/common/ArticleMarkdownContent"

import BodyText from "@components/common/BodyText"

import "./style.scss"

const MISSION_CONSTANT = "mission"
const VISION_CONSTANT = "vision"
const VALUES_CONSTANT = "values"

const MvvSection = props => {
  const [section, setSection] = useState(MISSION_CONSTANT)

  return (
    <div className="mvv-section">
      <div className="mvv-section__menu">
        <button
          className="mvv-section__button"
          onClick={() => setSection(MISSION_CONSTANT)}
        >
          <BodyText
            size="lg"
            color={section === MISSION_CONSTANT ? "dark" : "primary"}
            text={language[props.language].mission}
          />
        </button>
        <button
          className="mvv-section__button"
          onClick={() => setSection(VISION_CONSTANT)}
        >
          <BodyText
            size="lg"
            color={section === VISION_CONSTANT ? "dark" : "primary"}
            text={language[props.language].vision}
          />
        </button>
        <button
          className="mvv-section__button"
          onClick={() => setSection(VALUES_CONSTANT)}
        >
          <BodyText
            size="lg"
            color={section === VALUES_CONSTANT ? "dark" : "primary"}
            text={language[props.language].values}
          />
        </button>
      </div>
      <div className="mvv-section__content page-wrap">
        {section === MISSION_CONSTANT && (
          <ArticleMarkdownContent content={props.missionText} />
        )}
        {section === VISION_CONSTANT && (
          <ArticleMarkdownContent content={props.visionText} />
        )}
        {section === VALUES_CONSTANT && (
          <ArticleMarkdownContent content={props.valuesText} />
        )}
      </div>
    </div>
  )
}

MvvSection.propTypes = {
  missionText: PropTypes.string,
  visionText: PropTypes.string,
  valuesText: PropTypes.string,
  language: PropTypes.string,
}
MvvSection.defaultProps = {
  language: "es",
}

export default MvvSection
