import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"

import FadeInSection from "@components/common/FadeInSection"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import CustomImage from "@components/common/CustomImage"

import SplitTextImageSection from "@templates/homepage/components/SplitTextImageSection"
import MvvSection from "./components/MvvSection"
import TimelineSection from "./components/TimelineSection"

import "./style.scss"

const AboutPage = props => {
  const page =
    !!props.preview && !!props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

  return (
    <Layout className="about" isHomepage={false}>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">
        <SimpleBreadcrumbHero image={page.acfAbout.hero.image} overlayType="full" pageTitle={page.acfPageMeta.metaTitle} />
        <div className="page-wrap">
          <div className="about__subhero">
            <HeadingText
              className="about__subhero--overline mb-20px"
              text={page.acfAbout.hero.overline}
              size="md"
              color="dark-gray"
            />
            <HeadingText
              className="about__subhero--title mb-40px"
              color="primary"
              text={page.acfAbout.hero.title}
              size="lg2"
            />
            <BodyText
              className="about__subhero--subcopy"
              text={page.acfAbout.hero.subcopy}
              size="md2"
              color="dark-gray"
            />
            {page.acfAbout.primaryImage && (
              <CustomImage
                className="about__subhero--image responsive-image"
                src={page.acfAbout.primaryImage.sourceUrl}
                alt={page.acfAbout.primaryImage.altText}
                width={
                  page.acfAbout.primaryImageSize === "default" ? 820 : 1000
                }
              />
            )}
          </div>
        </div>
        
        <MvvSection
          missionText={page.acfAbout.missionText}
          visionText={page.acfAbout.visionText}
          valuesText={page.acfAbout.valuesText}
        />
        {page.acfAbout.timeline && !!page.acfAbout.timeline.length && (
          <TimelineSection timelineItems={page.acfAbout.timeline} />
        )}
        {/*
        <FadeInSection>
          <SplitTextImageSection
            backgroundColorToken="light-gray"
            image={page.acfAbout.ctaSection.image}
            imageSide="right"
            imageSize="default"
            titleHeadingSize="md3"
            textSectionOffset={0}
            overline={page.acfAbout.ctaSection.overline}
            title={page.acfAbout.ctaSection.title}
            cta2={page.acfAbout.ctaSection.cta}
          />
        </FadeInSection>
        */}
      </div>
    </Layout>
  )
}

AboutPage.propTypes = {
  language: PropTypes.string,
}

AboutPage.defaultProps = {
  language: "es",
}

export const query = graphql`
  query AboutPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "about" } } }) {
      edges {
        node {
          id
          uri
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfAbout {
            hero {
              overline
              subcopy
              title
              image {
                altText
                sourceUrl
              }
            }
            ctaSection {
              title
              overline
              image {
                altText
                sourceUrl
              }
              cta {
                url
                title
                target
              }
            }
            missionText
            valuesText
            visionText
            primaryImage {
              altText
              sourceUrl
            }
            timeline {
              date
              description
              title
              image {
                altText
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
const PREVIEW_QUERY = gql`
  query PreviewAboutPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "about" } } }) {
      edges {
        node {
          id
          uri
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
          acfAbout {
            hero {
              overline
              subcopy
              title
            }
            ctaSection {
              title
              overline
              image {
                altText
                sourceUrl
              }
              cta {
                url
                title
                target
              }
            }
            missionText
            valuesText
            visionText
            primaryImage {
              altText
              sourceUrl
            }
            timeline {
              date
              description
              title
              image {
                altText
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(AboutPage)
