import React, { useState } from "react"
import PropTypes from "prop-types"

import language from "@language"
import PageCarousel from "@components/PageCarousel"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import TimelineItem from "../TimelineItem"

import classnames from "classnames"
import moment from "moment"

import "./style.scss"

const TimelineSection = props => {
  //const [currentDecade, setDecade] = useState()
  const [currentYear, setYear] = useState()
  const [childEmbla, setChildEmbla] = useState()

  const sortedItems = props.timelineItems.sort((a, b) => {
    return moment(a.date).diff(moment(b.date))
  })



  let years = props.timelineItems.map(item => moment(item.date).year())
  years = years.filter((item, index) => {
    return years.indexOf(item) === index;
  });

  const handleInit = embla => {
    setChildEmbla(embla)
    handleSelect(embla)
  }
  const handleSelect = embla => {
    const selectedItem = sortedItems[embla.selectedScrollSnap()]
    //const decade = Math.floor(moment(selectedItem.date).year() / 10) * 10
    const year = moment(selectedItem.date).year()
    //setDecade(decade)
    setYear(year)
  }

  const handleYearClick = (e, year) => {
    if (!childEmbla) return
    const item = props.timelineItems.find(item => moment(item.date).year() === year)
    const index = sortedItems.indexOf(item)
    childEmbla.scrollTo(index)

  }

  return (
    <div className="timeline-section">
      <div className="timeline-section__menu">
        <HeadingText
          size="md"
          text={`${language[props.language].timeline}`}
        />
        <div className="timeline-section__years">
          {years.map((year, index) => {
            return (
              <button
                key={`year--${year}`}
                className="timeline-section__year"
                onClick={e => handleYearClick(e, year)}
              >
                <BodyText
                  className={classnames({ bold: currentYear === year })}
                  size="md2"
                  color={currentYear === year ? "primary" : "dark-gray"}
                  text={year}
                />
              </button>
            )
          })}
        </div>
      </div>
      <PageCarousel
        align="center"
        childKeyIdentifier="timeline-carousel"
        onInit={handleInit}
        onSelect={handleSelect}
        slides={sortedItems.map(item => (
          <TimelineItem
            image={item.image}
            date={item.date}
            title={item.title}
            description={item.description}
          />
        ))}
      />
    </div>
  )
}

TimelineSection.propTypes = {
  timelineItems: PropTypes.array,
  language: PropTypes.string,
}
TimelineSection.defaultProps = {
  language: "es",
}

export default TimelineSection
